import { ReactNode } from 'react'

import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { avatarFallback } from '@/lib/utils/utils'

export type UserType = {
  id?: string | null
  name?: string | null
  email?: string | null
  avatarUrl?: string | null
}

type Props = {
  user?: UserType | null
  children?: ReactNode
  size?: number
  avatarFallbackClassName?: string
}

export const UserAvatar = ({
  user,
  avatarFallbackClassName,
  size = 32,
}: Props) => {
  return (
    <Avatar style={{ width: size, height: size }}>
      {user?.avatarUrl && (
        <AvatarImage
          src={user.avatarUrl}
          alt={user?.name || user?.email || 'User'}
        />
      )}

      <AvatarFallback
        className={avatarFallbackClassName}
        delayMs={user?.avatarUrl ? 300 : 0}
        value={avatarFallback(user)}
      />
    </Avatar>
  )
}

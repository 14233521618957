import { PlaceDetailsResponse } from '@googlemaps/google-maps-services-js'
import { formatDistanceStrict } from 'date-fns'
import { enAU } from 'date-fns/locale'
import { PlaceholderValue } from 'next/dist/shared/lib/get-img-props'

import { SocialKeys } from '../data/socials'

export const prependUrl = (
  url?: string,
  options?: { siteSlug: string; domain?: string },
) => {
  if (!url) return '#'

  if (url.startsWith('/')) {
    if (options !== undefined && !!options.siteSlug && !options.domain) {
      return `/${options.siteSlug}${url}`
    }

    return url
  }

  // Check for valid http or https URLs
  if (/^https?:\/\//i.test(url)) {
    return url
  }

  // Check for mailto links and return as is
  if (/^mailto:/i.test(url)) {
    return url
  }

  // Check for valid email format and prepend with mailto
  if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/i.test(url)) {
    return 'mailto:' + url
  }

  // Default case: prepend with https
  return 'https://' + url
}

export const normalizeHandle = (
  initialInput: string | undefined,
  baseUrl: string,
  key: string,
): string => {
  const input = String(initialInput)

  // If the input includes http or https, return it as-is
  if (
    input.includes('http://') ||
    input.includes('https://') ||
    input.includes('www.')
  ) {
    return input
  }

  // Regular expression patterns for different keys
  const patterns: Record<string, RegExp> = {
    instagram: /(?:https?:\/\/)?(?:www\.)?instagram\.com\/(?:@)?([^\/\?]+)/i,
    facebook: /(?:https?:\/\/)?(?:www\.)?facebook\.com\/([^\/\?]+)/i,
    linkedin:
      /(?:https?:\/\/)?(?:www\.)?linkedin\.com\/(?:in|company)\/([^\/\?]+)/i,
    snapchat: /(?:https?:\/\/)?(?:www\.)?snapchat\.com\/add\/([^\/\?]+)/i,
    soundcloud: /(?:https?:\/\/)?(?:www\.)?soundcloud\.com\/([^\/\?]+)/i,
    tiktok: /(?:https?:\/\/)?(?:www\.)?tiktok\.com\/(?:@)?([^\/\?]+)/i,
    twitter: /(?:https?:\/\/)?(?:www\.)?twitter\.com\/(?:@)?([^\/\?]+)/i,
    youtube:
      /(?:https?:\/\/)?(?:www\.)?youtube\.com\/(?:channel\/)?([^\/\?]+)|^UC[\w-]+$/i,
    github: /(?:https?:\/\/)?(?:www\.)?github\.com\/([^\/\?]+)/i,
    dribbble: /(?:https?:\/\/)?(?:www\.)?(www\.)?dribbble\.com\/(.+)$/i,
    twitch: /(?:https?:\/\/)?(?:www\.)?(www\.)?twitch\.tv\/(.+)$/i,
    'facebook-messenger': /(?:https?:\/\/)?(?:www\.)?(www\.)?m\.me\/(.+)$/i,
    discord: /(?:https?:\/\/)?(?:www\.)?(www\.)?discord\.com\/invite\/(.+)$/i,
    onlyfans: /(?:https?:\/\/)?(?:www\.)?onlyfans\.com\/([^\/\?]+)/i,
    linktree: /(?:https?:\/\/)?(?:www\.)?(www\.)?linktr\.ee\/(.+)$/i,
    threads: /(?:https?:\/\/)?(?:www\.)?threads\.net\/(?:@)?([^\/\?]+)/i,
    bluesky: /(?:https?:\/\/)?(?:www\.)?bsky\.app\/(?:@)?([^\/\?]+)/i,
    vimeo: /(?:https?:\/\/)?(?:www\.)?(www\.)?vimeo\.com\/(.+)$/i,
    whatsapp: /^(?:\+)?([0-9]+)$/i,
    telegram: /(?:https?:\/\/)?(?:www\.)?(t\.me|telegram\.com)\/([^\/\?]+)/i,
    line: /(?:https?:\/\/)?(?:www\.)?line\.me\/ti\/p\/([^\/\?]+)/i,
  }

  const pattern = patterns[key]

  const match = input.match(pattern)

  if (match && match[1]) {
    if (key === 'youtube') {
      const isChannelId = match[1].startsWith('UC')
      // Use 'channel/' prefix for channel IDs, directly append username for others
      const youtubePath = isChannelId ? 'channel/' : ''
      return `${baseUrl}${youtubePath}${match[1]}`
    }

    if (key === 'linkedin' && input.includes('company/')) {
      return baseUrl.replace('in/', 'company/') + match[1]
    }

    if (key === 'whatsapp') {
      // Remove any non-digit characters
      const cleanNumber = match[1].replace(/\D/g, '')
      return `${baseUrl}${cleanNumber}`
    }

    // If input string matches the pattern, construct the normalized URL
    return baseUrl + match[1]
  } else if (input.startsWith('@')) {
    // If input string starts with '@', it is assumed to be a username
    if (key === 'youtube') {
      return baseUrl + input
    }

    return baseUrl + input.substring(1)
  } else {
    if (key === 'youtube') {
      return `${baseUrl}${input.startsWith('UC') ? 'channel/' : ''}${input}`
    }

    // In any other case, assume the input string is the username
    return baseUrl + input
  }
}

const normalizeEmailAddress = (email: string): string => {
  const pattern = /^mailto:/i

  // If the email address already starts with 'mailto:', return it as is
  if (pattern.test(email)) {
    return email
  }

  // Otherwise, prepend 'mailto:' to the email address
  return 'mailto:' + email
}

const normalizePhoneNumber = (phoneNumber: string): string => {
  const pattern = /^tel:/i

  // If the phone number already starts with 'tel:', return it as is
  if (pattern.test(phoneNumber)) {
    return phoneNumber
  }

  // Remove any non-numeric characters
  const cleanedNumber = phoneNumber.replace(/\D/g, '')

  // Prepend 'tel:' to the cleaned phone number
  return 'tel:' + cleanedNumber
}

export const getFullSocialUrl = ({
  key,
  websiteUrl,
  transformPhone = true,
  transformEmail = true,
}: {
  websiteUrl: string
  key: string
  transformPhone?: boolean
  transformEmail?: boolean
}) => {
  switch (key) {
    case 'instagram':
      return normalizeHandle(
        websiteUrl,
        'https://www.instagram.com/',
        'instagram',
      )
    case 'twitter':
      return normalizeHandle(websiteUrl, 'https://twitter.com/', 'twitter')
    case 'tiktok':
      return normalizeHandle(websiteUrl, 'https://tiktok.com/@', 'tiktok')
    case 'whatsapp':
      return normalizeHandle(
        websiteUrl,
        'https://api.whatsapp.com/send?phone=',
        'whatsapp',
      )
    case 'email':
      return transformEmail ? normalizeEmailAddress(websiteUrl) : websiteUrl
    case 'phone':
    case 'sms':
      return transformPhone ? normalizePhoneNumber(websiteUrl) : websiteUrl
    case 'facebook':
      return normalizeHandle(
        websiteUrl,
        'https://www.facebook.com/',
        'facebook',
      )
    case 'linkedin':
      return normalizeHandle(
        websiteUrl,
        'https://www.linkedin.com/in/',
        'linkedin',
      )
    case 'snapchat':
      return normalizeHandle(
        websiteUrl,
        'https://www.snapchat.com/add/',
        'snapchat',
      )
    case 'soundcloud':
      return normalizeHandle(
        websiteUrl,
        'https://www.soundcloud.com/',
        'soundcloud',
      )
    case 'youtube':
      return normalizeHandle(websiteUrl, 'https://www.youtube.com/', 'youtube')
    case 'github':
      return normalizeHandle(websiteUrl, 'https://www.github.com/', 'github')
    case 'dribbble':
      return normalizeHandle(websiteUrl, 'https://dribbble.com/', 'dribbble')
    case 'twitch':
      return normalizeHandle(websiteUrl, 'https://twitch.tv/', 'twitch')
    case 'facebook-messenger':
      return normalizeHandle(websiteUrl, 'https://m.me/', 'facebook-messenger')
    case 'discord':
      return normalizeHandle(
        websiteUrl,
        'https://discord.com/invite/',
        'discord',
      )
    case 'threads':
      return normalizeHandle(websiteUrl, 'https://threads.net/@', 'threads')
    case 'bluesky':
      return normalizeHandle(websiteUrl, 'https://bsky.app/profile/', 'bluesky')
    case 'vimeo':
      return normalizeHandle(websiteUrl, 'https://vimeo.com/', 'vimeo')
    case 'onlyfans':
      return normalizeHandle(websiteUrl, 'https://onlyfans.com/', 'onlyfans')
    case 'telegram':
      return normalizeHandle(websiteUrl, 'https://t.me/', 'telegram')
    case 'line':
      return normalizeHandle(websiteUrl, 'https://line.me/ti/p/', 'line')
    default:
      return prependUrl(websiteUrl)
  }
}

export const socialUrl = ({
  websiteUrl: url = '',
  ...props
}: {
  websiteUrl: string
  key: string
  transformPhone?: boolean
  transformEmail?: boolean
}) => {
  let websiteUrl = url

  if (websiteUrl.length > 1 && websiteUrl.charAt(0) === '#') {
    websiteUrl = websiteUrl.substring(1)
  }

  return getFullSocialUrl({ ...props, websiteUrl })
}

export const toDateTime = (secs: number) => {
  var t = new Date('1970-01-01T00:30:00Z') // Unix epoch start.
  t.setSeconds(secs)
  return t
}

export const rearrangeArray = (arr: any[]): any[] => {
  let evenIndexItems: any[] = []
  let oddIndexItems: any[] = []

  for (let i = 0; i < arr.length; i++) {
    if (i % 2 === 0) {
      evenIndexItems.push(arr[i])
    } else {
      oddIndexItems.push(arr[i])
    }
  }

  return evenIndexItems.concat(oddIndexItems)
}

export const avatarFallback = (
  user?: {
    name?: string | null
    email?: string | null
  } | null,
) => {
  const name = (user?.name || user?.email || '').toUpperCase()
  return name
    .split(' ')
    .slice(0, 2)
    .map((word) => word.slice(0, 1))
    .join('')
}

export const getGoogleDirectionLink = (
  place: PlaceDetailsResponse['data']['result'],
) => {
  if (!place?.geometry) {
    return null
  }

  return `https://www.google.com/maps/search/?api=1&query=${place.geometry?.location.lat}%2C${place.geometry?.location.lng}&query_place_id=${place.place_id}`
}

const formatDistanceLocale = {
  lessThanXSeconds: '{{count}}s',
  xSeconds: '{{count}}s',
  halfAMinute: '30s',
  lessThanXMinutes: '{{count}}m',
  xMinutes: '{{count}}m',
  aboutXHours: '{{count}}h',
  xHours: '{{count}}h',
  xDays: '{{count}}d',
  aboutXWeeks: '{{count}}w',
  xWeeks: '{{count}}w',
  aboutXMonths: '{{count}}mo',
  xMonths: '{{count}}mo',
  aboutXYears: '{{count}}y',
  xYears: '{{count}}y',
  overXYears: '{{count}}y',
  almostXYears: '{{count}}y',
}

const formatDistance = (
  token: keyof typeof formatDistanceLocale,
  count: any,
  options: any,
) => {
  options = options || {}

  const result = formatDistanceLocale[token].replace('{{count}}', count)

  if (options.addSuffix) {
    if (options.comparison > 0) {
      return 'in ' + result
    } else {
      return result + ' ago'
    }
  }

  return result
}

const locale = {
  ...enAU,
  formatDistance,
}

export const relativeTime = (date: string | number | Date) => {
  return formatDistanceStrict(new Date(date), new Date(), {
    locale,
    addSuffix: true,
  })
}

export const scrollToHash = function (elementId: string, delay?: number) {
  setTimeout(() => {
    const element = document.getElementById(elementId)
    element?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'nearest',
    })
  }, delay || 0)
}

export const flattenObject = (obj: any): any => {
  let result: { [k: string]: any } = {}

  for (let i in obj) {
    if (Array.isArray(obj[i])) {
      result[i] = obj[i].join(', ')
    } else if (typeof obj[i] === 'object' && obj[i] !== null) {
      let temp = flattenObject(obj[i])
      for (let j in temp) {
        if (temp[j] !== false) {
          result[i] = temp[j]
          break
        }
      }
    } else {
      if (obj[i] !== false) {
        result[i] = obj[i]
      }
    }
  }

  return result
}

export const handleSocialShare = (
  kind: SocialKeys,
  urlToShare: string,
  message: string = '',
) => {
  const encodedURL = encodeURIComponent(urlToShare)
  const encodedMessage = encodeURIComponent(message)
  const fullMessage = message ? `${encodedMessage} ${encodedURL}` : encodedURL

  switch (kind) {
    case 'facebook':
      window.open(
        `https://www.facebook.com/sharer/sharer.php?u=${encodedURL}`,
        '_blank',
      )
      break
    case 'twitter':
      window.open(
        `https://twitter.com/intent/tweet?text=${fullMessage}`,
        '_blank',
      )
      break
    case 'linkedin':
      window.open(
        `https://www.linkedin.com/sharing/share-offsite/?url=${encodedURL}`,
        '_blank',
      )
      break
    case 'whatsapp':
      window.open(`https://wa.me/?text=${fullMessage}`, '_blank')
      break
    case 'email':
      window.open(`mailto:?subject=Check this out&body=${fullMessage}`)
      break
    default:
  }
}

export const shimmer = (w: number, h: number) => `
<svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <linearGradient id="g">
      <stop stop-color="#eee" offset="20%" />
      <stop stop-color="#fff" offset="50%" />
      <stop stop-color="#eee" offset="70%" />
    </linearGradient>
  </defs>
  <rect width="${w}" height="${h}" fill="#eee" />
  <rect id="r" width="${w}" height="${h}" fill="url(#g)" />
  <animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite"  />
</svg>`

export const toBase64 = (str: string) =>
  typeof window === 'undefined'
    ? Buffer.from(str).toString('base64')
    : window.btoa(str)

export const placeholderShimmer = (w: number, h: number): PlaceholderValue =>
  `data:image/svg+xml;base64,${toBase64(shimmer(w, h))}`

export const getShortCountryCode = (data: any): string | undefined => {
  const addressComponents = data?.address_components

  for (let i = 0; i < (addressComponents || []).length; i++) {
    const types = addressComponents?.[i]?.types || ''
    if (types.includes('country') && types.includes('political')) {
      return addressComponents?.[i]?.short_name
    }
  }
}

export const extractGoogleVerificationCode = (input: string): string | null => {
  // Case 1: Just the verification code
  if (/^[a-zA-Z0-9_-]+$/.test(input)) {
    return input
  }

  // Case 2: Full meta tag
  const pattern: RegExp = /content="([^"]*)"/
  const match: RegExpExecArray | null = pattern.exec(input)

  if (match && match[1]) {
    return match[1]
  }

  // If neither case matches
  return null
}

export const pluralize = (
  count: number | undefined,
  singular: string,
  plural?: string,
) => {
  return count === 1 ? singular : plural || `${singular}s`
}

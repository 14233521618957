import { useTranslation } from 'react-i18next'

import {
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
} from '../ui/dropdown-menu'

type Props = {
  hideContactSupport?: boolean
  setFeedbackIsOpen: (isOpen: boolean) => void
}

export const UserButtonHelp = ({
  hideContactSupport,
  setFeedbackIsOpen,
}: Props) => {
  const { t } = useTranslation()

  return (
    <>
      <DropdownMenuSub>
        <DropdownMenuSubTrigger>{t('help')}</DropdownMenuSubTrigger>

        <DropdownMenuPortal>
          <DropdownMenuSubContent className="max-h-[250px] overflow-y-auto">
            <DropdownMenuItem asChild>
              <a href="https://support.fingertip.com/" target="_blank">
                {t('help_center')}
              </a>
            </DropdownMenuItem>

            {!hideContactSupport && (
              <>
                {/* @ts-ignore */}
                <DropdownMenuItem onClick={() => zE('messenger', 'open')}>
                  {t('user_contact_support')}
                </DropdownMenuItem>
              </>
            )}

            <DropdownMenuItem asChild>
              <a href="https://fingertip.com/report-content" target="_blank">
                {t('report_content')}
              </a>
            </DropdownMenuItem>

            <DropdownMenuItem
              onClick={() => {
                setFeedbackIsOpen(true)
              }}
            >
              {t('submit_feedback')}
            </DropdownMenuItem>
          </DropdownMenuSubContent>
        </DropdownMenuPortal>
      </DropdownMenuSub>
    </>
  )
}

'use client'
import { HeartIcon } from '@fingertip/icons'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'

import { FeedbackForm } from './feedback-form'

type FeedbackFormProps = {
  onSuccess?: () => void
  onOpenChange: (open: boolean) => void
  open: boolean
}

export const FeedbackDialog = ({
  onSuccess,
  open,
  onOpenChange,
}: FeedbackFormProps) => {
  const { t } = useTranslation()
  const [success, setSuccess] = useState(false)

  const handleSuccess = useCallback(() => {
    setSuccess(true)
    if (onSuccess) onSuccess()
  }, [onSuccess])

  const handleClose = useCallback(() => {
    onOpenChange(false)
  }, [onOpenChange])

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        {success ? (
          <div className="flex flex-col items-center justify-center py-5 text-center">
            <div className="mb-5 flex size-[80px] items-center justify-center rounded-3xl bg-muted">
              <HeartIcon
                className="fill-red-500 text-red-500"
                width={36}
                height={36}
              />
            </div>
            <DialogTitle className="h2 text-center">
              {t('thank_you_for_making_fingertip_better')}
            </DialogTitle>
            <div className="mt-4">
              <Button onClick={handleClose}>{t('close')}</Button>
            </div>
          </div>
        ) : (
          <>
            <DialogHeader>
              <DialogTitle className="h2 text-center">
                {t('give_us_feedback')}
              </DialogTitle>

              <DialogDescription className="text-center">
                {t('help_us_make_fingertip_better')}
              </DialogDescription>
            </DialogHeader>

            <FeedbackForm onSuccess={handleSuccess} />
          </>
        )}
      </DialogContent>
    </Dialog>
  )
}

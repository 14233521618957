'use client'
import { ChevronDownIcon } from '@fingertip/icons'
import Link from 'next/link'
import { usePostHog } from 'posthog-js/react'
import queryString from 'query-string'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'

import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { ButtonDiv } from '@/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { UserAvatar, UserType } from '@/components/user/user-avatar'
import { RootStoreContext } from '@/lib/stores/root-store'
import { getSupabaseClient } from '@/lib/supabase/supabase-client'
import { cn } from '@/lib/utils'
import { clientGetUrl } from '@/lib/utils/client-get-url'

import { FeedbackDialog } from '../shared/feedback-dialog'
import { LanguageSwitcher } from '../shared/language-switcher'
import { UserButtonHelp } from '../shared/user-button-help'

type Props = {
  avatarSize?: number
  showChevron?: boolean
  currentUser?: UserType | null
  className?: string
  avatarFallbackClassName?: string
  hideContactSupport?: boolean
}

export const UserButton = ({
  avatarSize = 24,
  showChevron = true,
  currentUser,
  className,
  avatarFallbackClassName,
  hideContactSupport,
}: Props) => {
  const { t } = useTranslation()
  const [hasMounted, setHasMounted] = useState(false)
  const [feedbackIsOpen, setFeedbackIsOpen] = useState(false)
  const supabase = getSupabaseClient()
  const {
    localStore: { setCurrentSiteMeta, setCurrentWorkspaceMeta },
  } = useContext(RootStoreContext)
  const postHog = usePostHog()

  const handleLogout = async () => {
    try {
      postHog.reset()
      const { error } = await supabase.auth.signOut()

      if (error) {
        throw error
      }

      setCurrentSiteMeta(null)
      setCurrentWorkspaceMeta(null)

      window.location.href = '/sign-in'
    } catch (error: any) {
      toast.error(error.message)
    }
  }

  useEffect(() => {
    setHasMounted(true)
  }, [])

  const queryParams = queryString.stringify({
    guest: true,
  })

  if (!hasMounted || !currentUser) {
    return (
      <>
        <DropdownMenu>
          <DropdownMenuTrigger className="outline-hidden cursor-pointer">
            <div
              className={cn(
                'flex flex-row items-center cursor-pointer',
                className,
              )}
            >
              <Avatar style={{ width: avatarSize, height: avatarSize }}>
                {!currentUser && <AvatarImage src="/avatar.png" alt="User" />}
                <AvatarFallback
                  className={avatarFallbackClassName}
                  value=""
                  noColor
                />
              </Avatar>

              {showChevron && (
                <ChevronDownIcon className="ml-0.75 text-muted-foreground size-3" />
              )}
            </div>
          </DropdownMenuTrigger>

          <DropdownMenuContent className="w-56" align="end" forceMount>
            <DropdownMenuItem asChild>
              <Link href={`${clientGetUrl()}/sign-in?${queryParams}`}>
                {t('site_dashboard')}
              </Link>
            </DropdownMenuItem>

            <DropdownMenuItem asChild>
              <Link href={`${clientGetUrl()}/customer-sign-in`}>
                {t('customer_dashboard')}
              </Link>
            </DropdownMenuItem>

            <DropdownMenuItem asChild>
              <Link href={`${clientGetUrl()}/home`}>
                {t('visit_home_page')}
              </Link>
            </DropdownMenuItem>

            <DropdownMenuSeparator />

            <LanguageSwitcher />

            <UserButtonHelp
              hideContactSupport={hideContactSupport}
              setFeedbackIsOpen={setFeedbackIsOpen}
            />

            <Link
              href={`${clientGetUrl()}/sign-in?${queryParams}`}
              className="my-2 block w-full"
            >
              <ButtonDiv size="sm" className="w-full" variant="secondary">
                {t('log_in')}
              </ButtonDiv>
            </Link>

            <Link
              href={`${clientGetUrl()}/sign-up?${queryParams}`}
              className="block w-full"
            >
              <ButtonDiv size="sm" className="w-full">
                {t('sign_up_submit')}
              </ButtonDiv>
            </Link>
          </DropdownMenuContent>
        </DropdownMenu>

        {feedbackIsOpen && (
          <FeedbackDialog
            open={feedbackIsOpen}
            onOpenChange={setFeedbackIsOpen}
          />
        )}
      </>
    )
  }

  return (
    <>
      <DropdownMenu defaultOpen>
        <DropdownMenuTrigger className="outline-hidden cursor-pointer">
          <div className={cn('flex flex-row items-center', className)}>
            <UserAvatar
              user={currentUser}
              size={avatarSize}
              avatarFallbackClassName={avatarFallbackClassName}
            />

            {showChevron && (
              <ChevronDownIcon className="ml-0.75 text-muted-foreground size-3" />
            )}
          </div>
        </DropdownMenuTrigger>

        <DropdownMenuContent className="w-56" align="end" forceMount>
          <DropdownMenuLabel className="font-normal" asChild>
            <Link
              href={`${clientGetUrl()}/account`}
              className="group flex flex-col justify-center space-y-2 pb-4 text-center"
            >
              <div className="flex flex-row items-center justify-center">
                <UserAvatar
                  user={currentUser}
                  size={56}
                  avatarFallbackClassName="text-base!"
                />
              </div>

              <p className="font-medium leading-none transition-colors group-hover:text-primary line-clamp-2">
                {currentUser?.name || currentUser?.email}
              </p>

              {!!currentUser?.name && (
                <p className="text-xs font-normal leading-normal text-muted-foreground line-clamp-1">
                  {currentUser?.email}
                </p>
              )}
            </Link>
          </DropdownMenuLabel>

          <DropdownMenuItem asChild>
            <Link href={`${clientGetUrl()}/sites`}>{t('site_dashboard')}</Link>
          </DropdownMenuItem>

          <DropdownMenuItem asChild>
            <Link href={`${clientGetUrl()}/my/dashboard`}>
              {t('customer_dashboard')}
            </Link>
          </DropdownMenuItem>

          <DropdownMenuItem asChild>
            <Link href={`${clientGetUrl()}/account`}>
              {t('user_account_settings')}
            </Link>
          </DropdownMenuItem>

          <DropdownMenuItem asChild>
            <Link href={`${clientGetUrl()}/home`}>{t('visit_home_page')}</Link>
          </DropdownMenuItem>

          <DropdownMenuSeparator />

          <LanguageSwitcher />

          <UserButtonHelp
            hideContactSupport={hideContactSupport}
            setFeedbackIsOpen={setFeedbackIsOpen}
          />

          <DropdownMenuItem onClick={handleLogout}>
            {t('user_sign_out')}
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      {feedbackIsOpen && (
        <FeedbackDialog
          open={feedbackIsOpen}
          onOpenChange={setFeedbackIsOpen}
        />
      )}
    </>
  )
}

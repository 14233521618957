import { EmojiArcIcon, EmojiSadIcon, EmojiSmileIcon } from '@fingertip/icons'
import { zodResolver } from '@hookform/resolvers/zod'
import { usePostHog } from 'posthog-js/react'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import { z } from 'zod'

import { Button } from '@/components/ui/button'
import { PHEventName } from '@/lib/posthog/events'

import { TextareaField } from '../fields/textarea-field'

const schema = z.object({
  question1: z.string().optional(),
  question2: z.string().optional(),
})

export type Schema = z.infer<typeof schema>

type Props = {
  surveyId?: string
  onSuccess?: () => void
}

export const FeedbackForm = ({
  surveyId = '018d5967-68a5-0000-8fce-de6e642333cc',
  onSuccess,
}: Props) => {
  const { t } = useTranslation()
  const postHog = usePostHog()
  const { register, control, handleSubmit } = useForm<Schema>({
    defaultValues: {
      question1: '',
      question2: '',
    },
    resolver: zodResolver(schema),
    mode: 'all',
  })

  const submitHandler = useCallback(
    async (input: Schema) => {
      postHog.capture(PHEventName.SURVEY_SENT, {
        $survey_id: surveyId,
        $survey_response: input.question1,
        $survey_response_1: input.question2,
      })
      toast.success(t('feedback_submitted'))
      onSuccess?.()
    },
    [onSuccess, postHog, surveyId, t],
  )

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <div className="mb-4 flex flex-row items-center space-x-4">
        {[
          {
            id: 'HAPPY',
            label: t('happy'),
            icon: (
              <EmojiSmileIcon
                width={36}
                height={36}
                className="text-green-500"
              />
            ),
          },
          {
            id: 'NEUTRAL',
            label: t('neutral'),
            icon: (
              <EmojiArcIcon
                width={36}
                height={36}
                className="text-yellow-500"
              />
            ),
          },
          {
            id: 'UNHAPPY',
            label: t('unhappy'),
            icon: (
              <EmojiSadIcon width={36} height={36} className="text-red-500" />
            ),
          },
        ].map((item, index) => (
          <div key={index} className="flex flex-col w-full">
            <input
              {...register('question1')}
              type="radio"
              value={item.id}
              id={`${item.id}`}
              className="peer hidden"
            />
            <label
              htmlFor={`${item.id}`}
              className="flex h-[80px] w-full cursor-pointer flex-col items-center justify-center rounded-2xl bg-card shadow-sm ring-offset-background peer-checked:bg-card peer-checked:outline-hidden peer-checked:ring-2 peer-checked:ring-ring peer-checked:ring-offset-2"
            >
              {item.icon}
            </label>

            <label
              htmlFor={`${item.id}`}
              className="mt-2 w-full text-center text-[10px] font-bold"
            >
              {item.label}
            </label>
          </div>
        ))}
      </div>

      <TextareaField
        control={control}
        name="question2"
        placeholder={t('leave_some_feedback')}
        minRows={2}
        className="w-full"
      />

      <Button type="submit" className="w-full">
        {t('submit')}
      </Button>
    </form>
  )
}

import { env } from '@/env'

export const clientGetUrl = () => {
  let url =
    env.NEXT_PUBLIC_SITE_URL ?? // Set this to your site URL in production env.
    'http://192.168.0.91:3000'
  // Make sure to include `https://` when not localhost.
  url = url.includes('http') ? url : `https://${url}`
  // Remove trailing slash if present
  url = url.endsWith('/') ? url.slice(0, -1) : url
  return url
}

export const apiGetUrl = () => {
  if (env.NODE_ENV === 'production') {
    // In production, use creator.fingertip.com directly
    return 'https://creator.fingertip.com'
  } else {
    // In development, use local URL
    let url = env.NEXT_PUBLIC_SITE_URL ?? 'http://192.168.0.91:3000'
    url = url.includes('http') ? url : `https://${url}`
    url = url.endsWith('/') ? url.slice(0, -1) : url
    return url
  }
}

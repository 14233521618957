/**
 * This lists all possible names of
 * event names
 */
export enum PHEventName {
  // Internal Event Names ($)
  PAGE_VIEW = '$pageview',
  SURVEY_SENT = 'survey sent',

  // CUSTOM EVENT NAMES
  VIEW_BOOKINGS = 'ViewBookings',
  COMPLETE_BOOKING_ONBOARDING = 'CompleteBookingOnboarding',
  COMPLETE_STORE_ONBOARDING = 'CompleteStoreOnboarding',
  COMPLETE_PAGE_ONBOARDING = 'CompletePageOnboarding',
  AI_USE_AI_BUTTON_CLICK = 'AIUseAIButtonClick',
  AI_GENERATE_IMAGE = 'AIGenerateImage',
  AI_PROMPT_SHORTCUT_CLICK = 'AIPromptShortcutClick',
  VIEW_UPGRADE_CONTENT = 'ViewUpgradeContent',
  CLICK_UPGRADE = 'ClickUpgrade',
  SOCIAL_FEED_POST_IMAGE_ERROR = 'SocialFeedPostImageError',
  PAGE_CLAIM_CLICK = 'PageClaimClick',
  PAGE_LINK_CLICK = 'PageLinkClick',
  GOOGLE_WALLET_CLICK = 'GoogleWalletClick',
  APPLE_WALLET_CLICK = 'AppleWalletClick',
  MAKE_YOUR_OWN_CLICK = 'MakeYourOwnClick',
}

'use client'

import i18next from 'i18next'
import { useRouter } from 'nextjs-toploader/app'
import { useTranslation as useTranslationI18N } from 'react-i18next'

import { LANGUAGES } from '@/lib/data/locales'
import { languages } from '@/lib/i18n/settings'

import {
  DropdownMenuCheckboxItem,
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
} from '../ui/dropdown-menu'

export const LanguageSwitcher = () => {
  const { i18n } = useTranslationI18N()
  const currentLocale = i18n.language
  const router = useRouter()

  const handleChangeLanguage = (value: string) => {
    i18next.changeLanguage(value)
    router.refresh()
  }

  const current = LANGUAGES.find((item) => item.id === currentLocale)

  return (
    <DropdownMenuSub>
      <DropdownMenuSubTrigger>
        <div>
          <div>Language</div>
          <div className="text-muted-foreground text-xs">{current?.label}</div>
        </div>
      </DropdownMenuSubTrigger>

      <DropdownMenuPortal>
        <DropdownMenuSubContent className="max-h-[250px] overflow-y-auto">
          {languages.map((lang) => {
            const current = LANGUAGES.find((item) => item.id === lang)

            return (
              <DropdownMenuCheckboxItem
                key={lang}
                onCheckedChange={() => handleChangeLanguage(lang)}
                checked={lang === currentLocale}
              >
                {current?.emoji} {current?.label}
              </DropdownMenuCheckboxItem>
            )
          })}
        </DropdownMenuSubContent>
      </DropdownMenuPortal>
    </DropdownMenuSub>
  )
}

export const LANGUAGES = [
  { id: 'ar', label: 'العربية', emoji: '🇸🇦' },
  { id: 'cs', label: 'Čeština', emoji: '🇨🇿' },
  { id: 'da', label: 'Dansk', emoji: '🇩🇰' },
  { id: 'de', label: 'Deutsch', emoji: '🇩🇪' },
  { id: 'el', label: 'Ελληνικά', emoji: '🇬🇷' },
  { id: 'en-GB', label: 'English (UK)', emoji: '🇬🇧' },
  { id: 'en-US', label: 'English (US)', emoji: '🇺🇸' },
  { id: 'es', label: 'Español', emoji: '🇪🇸' },
  { id: 'fi', label: 'Suomi', emoji: '🇫🇮' },
  { id: 'fr', label: 'Français', emoji: '🇫🇷' },
  { id: 'hi', label: 'हिन्दी', emoji: '🇮🇳' },
  { id: 'id', label: 'Bahasa Indonesia', emoji: '🇮🇩' },
  { id: 'it', label: 'Italiano', emoji: '🇮🇹' },
  { id: 'ja', label: '日本語', emoji: '🇯🇵' },
  { id: 'ko', label: '한국어', emoji: '🇰🇷' },
  { id: 'ms', label: 'Bahasa Melayu', emoji: '🇲🇾' },
  { id: 'nl', label: 'Nederlands', emoji: '🇳🇱' },
  { id: 'pl', label: 'Polski', emoji: '🇵🇱' },
  { id: 'pt', label: 'Português', emoji: '🇵🇹' },
  { id: 'ro', label: 'Română', emoji: '🇷🇴' },
  { id: 'ru', label: 'Русский', emoji: '🇷🇺' },
  { id: 'sk', label: 'Slovenčina', emoji: '🇸🇰' },
  { id: 'sl', label: 'Slovenščina', emoji: '🇸🇮' },
  { id: 'sv', label: 'Svenska', emoji: '🇸🇪' },
  { id: 'th', label: 'ไทย', emoji: '🇹🇭' },
  { id: 'tl', label: 'Filipino', emoji: '🇵🇭' },
  { id: 'tr', label: 'Türkçe', emoji: '🇹🇷' },
  { id: 'uk', label: 'Українська', emoji: '🇺🇦' },
  { id: 'vi', label: 'Tiếng Việt', emoji: '🇻🇳' },
  { id: 'zh', label: '中文', emoji: '🇨🇳' },
]
